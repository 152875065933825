import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import ContentContainer from "../../../templates/ContentContainer"
import bgImg from "../../../assets/BackgroundImages/WalletsCobalt/cobalt-hero.png"
import cobaltMockupImg from "../../../assets/BackgroundImages/WalletsCobalt/cobalt-mockup.png"
import Typography from "../../../components/Typography"
import CobaltButton from "./CobaltButton"

const CobaltHero = () => {
    const { formatMessage } = useIntl()

    return (
        <section
            className="pt-[130px] min-h-[70vh] lg:min-h-0 lg:h-[700px] bg-cover flex items-center"
            style={{ backgroundImage: `url(${bgImg})` }}
        >
            <ContentContainer>
                <div className="grid grid-cols-1 md:grid-cols-2 w-full justify-between items-center gap-y-10 gap-x-10 py-12">
                    <div className="lg:max-w-[400px]">
                        <Typography.H1>
                            {formatMessage({ id: "wallets.cobalt.hero.title" })}
                        </Typography.H1>
                        <Typography.Body1 className="mt-4">
                            {formatMessage({ id: "wallets.cobalt.hero.body" })}
                        </Typography.Body1>
                        <hr className="border-white w-12 my-9" />
                        <CobaltButton />
                    </div>
                    <div>
                        <img
                            className="m-auto"
                            src={cobaltMockupImg}
                            alt="cobalt app"
                        />
                    </div>
                </div>
            </ContentContainer>
        </section>
    )
}

export default CobaltHero
