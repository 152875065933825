import React from "react"
import NewsletterForm from "../../components/NewsletterForm"
import {
    CobaltGuides,
    CobaltHelmet,
    CobaltHero,
    CobaltDownload,
    CobaltFeatures,
} from "../../sections/wallets/cobalt"
import Layout from "../../templates/Layout"
import footerImg from "../../assets/BackgroundImages/WalletsCobalt/cobalt-footer-bg.png"

const Cobalt = () => {
    return (
        <Layout>
            <div className="bg-horizen-hp-bg">
                <CobaltHelmet />
                <CobaltHero />
                <CobaltFeatures />
                <div
                    className="bg-no-repeat lg:[background-position-y:-200px]"
                    style={{ backgroundImage: `url(${footerImg})` }}
                >
                    <CobaltDownload />
                    <CobaltGuides />
                    <NewsletterForm />
                </div>
            </div>
        </Layout>
    )
}

export default Cobalt
