import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import ButtonCustomContent from "../../../components/ButtonCustomContent"
import cobaltLogoSVG from "../../../assets/BackgroundImages/WalletsCobalt/cobalt-logo.svg"

const CobaltButton = () => {
    const { formatMessage } = useIntl()
    return (
        <ButtonCustomContent
            as="external-link"
            openNewTab
            href={formatMessage({
                id: "wallets.cobalt.download.btn.link",
            })}
        >
            <div className="flex items-center">
                <img
                    className="inline mr-3"
                    src={cobaltLogoSVG}
                    alt="cobalt logo"
                />

                {formatMessage({
                    id: "wallets.cobalt.download.btn.text",
                })}
            </div>
        </ButtonCustomContent>
    )
}

export default CobaltButton
