import { useIntl } from "gatsby-plugin-intl"
import React from "react"
import Typography from "../../../components/Typography"
import ContentContainer from "../../../templates/ContentContainer"
import CobaltButton from "./CobaltButton"

const CobaltDownload = () => {
    const { formatMessage } = useIntl()

    return (
        <section className="pt-9 pb-20">
            <ContentContainer>
                <div className="grid lg:grid-cols-2">
                    <div>
                        <Typography.H2>
                            {formatMessage({
                                id: "wallets.cobalt.download.title",
                            })}
                        </Typography.H2>
                        <Typography.Body1 className="mt-6">
                            {formatMessage({
                                id: "wallets.cobalt.download.body",
                            })}
                        </Typography.Body1>
                    </div>
                    <div className="flex mt-6 lg:mt-0 lg:items-center lg:justify-end">
                        <CobaltButton />
                    </div>
                </div>
            </ContentContainer>
        </section>
    )
}

export default CobaltDownload
