/* eslint-disable react/no-array-index-key */
/* eslint-disable no-plusplus */
import { useIntl } from "gatsby-plugin-intl"
import React from "react"
import ButtonCustomContent from "../../../components/ButtonCustomContent"
import Typography from "../../../components/Typography"
import ContentContainer from "../../../templates/ContentContainer"

interface Guide {
    title: string
    href: string
}

const CobaltGuides = () => {
    const { messages } = useIntl()

    const getGuides = () => {
        const guides: Guide[] = []
        for (
            let i = 0;
            i < +messages["wallets.cobalt.guides.list.length"];
            i++
        ) {
            guides.push({
                title: `${messages[`wallets.cobalt.guides.list.${i}.title`]}`,
                href: `${messages[`wallets.cobalt.guides.list.${i}.link`]}`,
            })
        }
        return guides
    }

    return (
        <section className="text-center pt-20 pb-48">
            <ContentContainer>
                <Typography.H2>User Guides and Tutorials</Typography.H2>
                <div className="grid md:grid-cols-2 gap-2 mt-14">
                    {getGuides().map((guide, index) => (
                        <ButtonCustomContent
                            key={`guide-${index}`}
                            as="external-link"
                            openNewTab
                            href={guide.href}
                            variant="gradient"
                            capitalLetters={false}
                        >
                            {guide.title}
                        </ButtonCustomContent>
                    ))}
                </div>
            </ContentContainer>
        </section>
    )
}

export default CobaltGuides
