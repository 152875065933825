/* eslint-disable no-plusplus */
/* eslint-disable react/no-array-index-key */
import { useIntl } from "gatsby-plugin-intl"
import React, { useCallback } from "react"
import Typography from "../../../components/Typography"
import ContentContainer from "../../../templates/ContentContainer"

interface Feature {
    title: string
    list: string[]
    imgURL: string
    imgAlt: string
}

const Feature: React.FC<{ data: Feature }> = ({ data }) => {
    return (
        <div className="py-16 lg:gap-x-10 border-t border-white/50 last-of-type:border-b flex flex-col lg:flex-row lg:items-center">
            <div className="flex grow max-w-[345px] shrink-0 gap-x-6 lg:gap-x-10 items-center">
                <img
                    src={data.imgURL}
                    alt={data.imgAlt}
                    className="h-14 w-14 md:h-[80px] md:w-[80px] shrink-0"
                />
                <Typography.H3 className="w-full">{data.title}</Typography.H3>
            </div>
            <ul className="mt-6 lg:mt-0">
                {data.list.map((item, index) => (
                    <li
                        key={`feature-list-${index}`}
                        className="mt-4 list-outside ml-4 list-disc text-horizen-content-grey"
                    >
                        <Typography.Body1 className="inline">
                            {item}
                        </Typography.Body1>
                    </li>
                ))}
            </ul>
        </div>
    )
}

const CobaltFeatures = () => {
    const { messages } = useIntl()

    const getFeatures = useCallback((): Feature[] => {
        const features: Feature[] = []

        for (
            let i = 0;
            i < +messages["wallets.cobalt.features.list.length"];
            i++
        ) {
            const list: string[] = []

            for (
                let j = 0;
                j < +messages[`wallets.cobalt.features.list.${i}.list.length`];
                j++
            ) {
                list.push(
                    `${messages[`wallets.cobalt.features.list.${i}.list.${j}`]}`
                )
            }

            features.push({
                title: `${messages[`wallets.cobalt.features.list.${i}.title`]}`,
                imgURL: `${
                    messages[`wallets.cobalt.features.list.${i}.imgURL`]
                }`,
                imgAlt: `${
                    messages[`wallets.cobalt.features.list.${i}.imgAlt`]
                }`,
                list,
            })
        }

        return features
    }, [messages])

    return (
        <section className="py-20">
            <ContentContainer>
                <div>
                    {getFeatures().map((feature, index) => (
                        <Feature key={`features-${index}`} data={feature} />
                    ))}
                </div>
            </ContentContainer>
        </section>
    )
}

export default CobaltFeatures
